* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--background-color, #ffffff);
  color: var(--text-color, #000000);
  font-family: 'ITCAvantGardeGothicLtDemi', sans-serif;
  overflow: auto;
}

@font-face {
  font-family: 'ITCAvantGardeGothicLtDemi';
  src: url('ITC Avant Garde Gothic LT Demi.ttf') format('truetype');
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

svg {
  user-select: none;
  font-size: 150px;
}

@media screen and (max-width: 768px) {
  svg {
    font-size: 120px;
  }
}

@media screen and (max-width: 480px) {
  svg {
    font-size: 90px;
  }
}

:root {
  --mouse-x: 50%;
  --mouse-y: 50%;
}

.app {
  min-height: 100vh;
}

.navbar {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  background: var(--navbar-background-color, #ffffffdd);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  z-index: 1000;
  outline: 0.5px solid var(--text-color, #000000);
  transition: all 0.3s ease-in-out;
}

.navbar>.logo {
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 832px) {
  .navbar {
    width: calc(100%);
    top: 0;
    border-radius: 0;
  }

  .navbar>.logo {
    opacity: 0;
    width: 0;
    margin: 0;
  }
}

@media screen and (max-width: 500px) {
  .navbar {
    width: 100%;
    border-radius: 0;
  }
}

@media screen and (max-width: 500px) {
  .navbar {
    display: none;
  }
}

.nav-links {
  display: flex;
  gap: 2rem;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
}

.nav-link {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: var(--text-color, #000000);
}

section {
  min-height: 100vh;
  padding: 6rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-section {
  justify-content: center;
  text-align: center;
}

.hero-section h1 {
  font-size: 10rem;
  font-weight: bold;
  margin: 0;
  transition: font-size 0.3s ease-in-out;
  position: absolute;
  pointer-events: none;
  margin-top: -30px;
}

@media screen and (max-width: 1200px) {
  .hero-section h1 {
    font-size: 10rem;
  }
}

@media screen and (max-width: 768px) {
  .hero-section h1 {
    font-size: 8rem;
  }
}

@media screen and (max-width: 480px) {
  .hero-section h1 {
    font-size: 6rem;
  }
}

.gradient-blur {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120px;
  pointer-events: none;
  z-index: 5;
}

.gradient-blur>div,
.gradient-blur::before,
.gradient-blur::after {
  position: absolute;
  inset: 0;
}

.gradient-blur::before {
  content: "";
  z-index: 1;
  backdrop-filter: blur(0.5px);
  mask: linear-gradient(to top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 12.5%,
      rgba(0, 0, 0, 1) 25%,
      rgba(0, 0, 0, 0) 37.5%);
}

.gradient-blur>div:nth-of-type(1) {
  z-index: 2;
  backdrop-filter: blur(1px);
  mask: linear-gradient(to top,
      rgba(0, 0, 0, 0) 12.5%,
      rgba(0, 0, 0, 1) 25%,
      rgba(0, 0, 0, 1) 37.5%,
      rgba(0, 0, 0, 0) 50%);
}

.gradient-blur>div:nth-of-type(2) {
  z-index: 3;
  backdrop-filter: blur(2px);
  mask: linear-gradient(to top,
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 1) 37.5%,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) 62.5%);
}

.gradient-blur>div:nth-of-type(3) {
  z-index: 4;
  backdrop-filter: blur(4px);
  mask: linear-gradient(to top,
      rgba(0, 0, 0, 0) 37.5%,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 1) 62.5%,
      rgba(0, 0, 0, 0) 75%);
}

.gradient-blur>div:nth-of-type(4) {
  z-index: 5;
  backdrop-filter: blur(6px);
  mask: linear-gradient(to top,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 1) 62.5%,
      rgba(0, 0, 0, 1) 75%,
      rgba(0, 0, 0, 0) 87.5%);
}

.gradient-blur>div:nth-of-type(5) {
  z-index: 6;
  backdrop-filter: blur(6px);
  mask: linear-gradient(to top,
      rgba(0, 0, 0, 0) 62.5%,
      rgba(0, 0, 0, 1) 75%,
      rgba(0, 0, 0, 1) 87.5%,
      rgba(0, 0, 0, 0) 100%);
}

.gradient-blur>div:nth-of-type(6) {
  z-index: 7;
  backdrop-filter: blur(6px);
  mask: linear-gradient(to top,
      rgba(0, 0, 0, 0) 75%,
      rgba(0, 0, 0, 1) 87.5%,
      rgba(0, 0, 0, 1) 100%);
}

.gradient-blur::after {
  content: "";
  z-index: 8;
  backdrop-filter: blur(6px);
  mask: linear-gradient(to top,
      rgba(0, 0, 0, 0) 87.5%,
      rgba(0, 0, 0, 1) 100%);
}

#home h1 {
  font-size: 105px;
  font-weight: 400;
  margin: 0;
  transition: font-size 0.3s ease-in-out;
  position: absolute;
  pointer-events: none;
  margin: 0;
  padding: 0;
  margin-top: -23px;
  margin-left: -2px;
}

#home {
  overflow-x: hidden;
}

/* Example of a CSS-based approach to dark mode using a media query */
@media (prefers-color-scheme: dark) {
  :root {
    /* Instead of using invert(), define a proper dark theme color set or partial invert. */
    /* This example uses slightly darker backgrounds and lighter text, but you can customize. */
    --background-color: #121212;
    --navbar-background-color: #121212dd;
    --text-color: #eee;
  }
}